/* Cart page css */
  
  .cart-checkout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .cart-checkout-description {
    color: var(--light-gray);
    font-size: 0.8rem;
  }
  
  .cart-checkout-total {
    color: var(--writing);
    font-size: 1.5rem;
    margin: 0;
  }

  .checkout-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    min-height: 50px;
    max-height: 100px;
    padding: 1rem;
    border-radius: 9px;
    background: var(--cart-checkout);
    margin-bottom: 1em;
  }
  
  .checkout-item-item {
    color: var(--white);
    align-self: center;
    padding: 3px 6px 3px 6px;
    border-radius: 9px;
    background: var(--primary);
    font-weight: bold;
  }
  
  .checkout-item-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    min-height: 9vh;
  }
  
  .cart-container {
    width: 80%;
    max-width: 550px;
  }
  
  .cart-date {
    color: var(--primary);
  }
  
  .cart-title {
    color: var(--writing);
    font-size: clamp(1.5rem, 5vw, 4rem);
    margin: 0;
  }
  
  .cart-description {
    color: var(--light-gray);
    font-size: clamp(1rem, 2vw, 1.1rem);
  }

  .link {
    color: var(--primary);
    font-weight: 600;
  }
  