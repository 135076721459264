
.firebaseui-container {
    background: transparent !important;
    color: var(--writing) !important;
    min-height: 150px;
    min-width: 300px !important;
    max-width: 100% !important;
    border-radius: 20px !important;
    padding: 0px;
    box-shadow: none;
    margin-top: unset;
  }

  .firebaseui-id-page-password-sign-in {
    /* border: var(--light-gray) 1px solid !important; */
  }

  .firebaseui-text {
    color: var(--writing) !important;
  }

  .firebaseui-id-page-password-recovery {
    border: none !important;
  }

.firebaseui-input-invalid {
  color: var(--writing) !important;
}

.firebaseui-idp-button.mdl-button.mdl-js-button.mdl-button--raised.firebaseui-idp-password.firebaseui-id-idp-button {
  background: var(--primary) !important;
}

.firebaseui-idp-button {
  max-width: 408px!important;
  padding-left: calc(calc(100% - 155px) / 2) !important;
  padding-right: calc(calc(100% - 155px) / 2) !important;
}

/* .mdl-button {
  padding: 0 !important;
} */

.firebaseui-id-page-sign-in {
  box-shadow: none !important;
}

.firebaseui-id-secondary-link.firebaseui-button.mdl-button.mdl-js-button.mdl-button--primary {
color: var(--secondary) !important;
}

.firebaseui-textfield.mdl-textfield .firebaseui-input {
    border-color: var(--light-gray) !important;
    color: var(--writing);
}
.mdl-textfield__label {
  color: var(--light-gray) !important;
}

.firebaseui-card-header {
  display: none;
}

.firebaseui-subtitle, .firebaseui-text {
  color: rgba(255, 255, 255, 0.87);
}
.firebaseui-form-actions .mdl-button--raised.mdl-button--colored.firebaseui-button {
  background: var(--secondary);
}

.firebaseui-card-content {
  padding: 0 !important;
}