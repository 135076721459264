.payouts-containersk {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.bank-details-containersk {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
    height: 100%;
    background: var(--surface);
    border-radius: 9px;
    padding: 1em;
    margin-bottom: 1em;
    animation: skeleton 1.5s ease infinite alternate;
}

@keyframes skeleton {
    to {
        opacity: 0.5;
    }
}

.event-box-titlesk {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 10%;
    margin-top: 1rem;
}

.bank-detailssk {
    display: flex;
    flex-direction: row;
    align-items: space-between;
    width: 97%;
    height: 100%;
    background: var(--on-surface);
    border-radius: 9px;
    padding: 0.5em;
    margin-bottom: 0.75rem;
    min-height: 30px;
}

.bank-details-itemsk {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-left: 1em;
    color: var(--writing);
    font-size: 1rem;
    font-weight: 500;
}

.circular {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
}

.logo-skeleton {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 1rem;
    animation: skeleton 1.5s ease infinite alternate;
}

.logo-skeleton-title {
    color: var(--primary);
    font-size: clamp(1.5rem, 5vw, 2rem);
    font-weight: 600;
}

.logo-skeleton-text {
    color: var(--light-gray);
    font-size: clamp(1rem, 5vw, 1.5rem);
    font-weight: 500;
}

.logo-skeleton-text-two {
    color: var(--light-gray);
    font-size: clamp(0.6rem, 5vw, 0.9rem);
    font-weight: 500;
}

.event-imagesk {
    width: 350px;
    height: 262px;
    border-radius: 9px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: var(--light-gray);
    animation: skeleton 1.5s ease infinite alternate;
}

.event-titlesk {
    background-color: var(--light-gray);
    margin: 0;
    height: 3rem;
    width: 50vw;
    border-radius: 9px;
    animation: skeleton 1.5s ease infinite alternate;
}


.event-infosk {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
}

.event-datesk {
    background-color: var(--light-gray);
    margin-right: 30px;
    height: 1.5rem;
    width: 10vw;
    border-radius: 9px;
    animation: skeleton 1.5s ease infinite alternate;
}

.event-descriptionsk {
    height: 1.3rem;
    margin-top: 1em;
    margin-bottom: 1em;
    background-color: var(--light-gray);
    width: 50vw;
    border-radius: 9px;
    animation: skeleton 1.5s ease infinite alternate;
}

.event-herosk {
    min-height: -moz-fit-content;
    min-height: fit-content;
    background: linear-gradient(0deg, var(--secondary), var(--primary));
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    text-align: center;
  }
