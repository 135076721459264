.icon {
    font-size: 1em;
    width: 1em;
    height: 1em;
    fill: currentColor;
  }

  .icon--sm {
    font-size: 0.75em;
    width: 1em;
    height: 1em;
  }

  .icon--lg {
    font-size: 1em;
    width: 2em;
    height: 2em;
  }

  .icon--xl {
    font-size: 1.5em;
    width: 2em;
    height: 2em;
  }

  .icon--xxl {
    font-size: 2em;
    width: 2.5em;
    height: 2.5em;
  }

  .o {
    color: var(--primary);
  }

  .icon-btn-o {
    display: flex;
    justify-content: center;
    color: var(--white);
    background: var(--primary-light);
    border-radius: 50%;
    padding: 0.5em;
    margin: 0.5em;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .icon-btn-o:hover {
    background: var(--primary-active);
  }

  .icon-mar-right {
    margin-right: 0.50rem;
  }

  .icon-btn-g {
    display: flex;
    justify-content: center;
    color: var(--white);
    background: var(--light-gray);
    border-radius: 50%;
    padding: 0.5em;
    margin: 0.5em;
    border: none;
    cursor: not-allowed;
    transition: all 0.3s ease-in-out;
  }