/* body {
  margin: 0;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --background: rgb(0, 0, 0);
  --dark-dark-mode: #121212;
  --primary: #8146F6;
  --primary-active: #371f69;
  --purple-active: #443244;
  --primary-light: #8146F6;
  --error: #cf6679;
  --success: #1aa41a;
  --secondary: #25abef;
  --white: #ffffff;
  --surface: #1c1c1c;
  --gray-active: #373737;
  --light-gray: #A2A0A0;
  --on-surface: #272727;
  --writing: #e8e7e7;
  --cart-checkout:#373737;
  --disabled: #181818;
} */

body {
  margin: 0;
  font-family: 'Lato', 'Roboto', "Montserrat",
    sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --background: #fff;
  --dark-dark-mode: #dad9d9;
  --primary: #8146F6;
  --primary-active: #a088cf;
  --primary-light: #9b6ef6;
  --error: #cf6679;
  --success: #1aa41a;
  --secondary: #25abef;
  --white: #ffffff;
  --surface: #ebebeb;
  --gray-active: #d6d6d6;
  --light-gray: #4D4C4C;
  --on-surface: #efefef;
  --writing: #121212;
  --cart-checkout: #ebebeb;
  --purple-active: #b08bb0b7;
  --disabled: #777575;
}


#bold {
  font-weight: 500;
}

#root {
  height: 100%;
  width: 100%;
}

section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 5vh;
}

.main {
  background: var(--background);
  min-height: 94vh;
  min-width: 100%;
  height: -moz-fit-content;
  height: fit-content;
}

.titles {
  color: var(--writing);
  font-size: 1.8rem;
}

.titles-2 {
  padding: 0.25rem;
  color: var(--writing);
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
}

.mar-left {
  margin-left: auto;
}

.dropdown {
  float: left;
  overflow: hidden;
}

/* Dropdown button */
.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--surface);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  font-size: 1rem;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  background-color: var(--gray-active);
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.hero-title {
  align-self: center;
  font: optional;
  font-size: clamp(1.2rem, 5vw, 3rem);
  font-weight: 500;
  color: #003366;
  /* background: -webkit-linear-gradient(360deg, var(--primary), var(--secondary));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent; */
  margin-bottom: 1rem;
  margin-block-start: 0px;
}

.hero-title-coloured {
  align-self: center;
  font-size: clamp(2rem, 5vw, 5rem);
  font-weight: 300;
  color: #0033cc ;
  /* background: -webkit-linear-gradient(360deg, var(--primary), var(--secondary));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent; */
  margin-bottom: 2rem;
  margin-block-start: 0px;
}

.discover-description {
  font-weight: 400;
  font-style: normal;
}

.montserrat {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.montserrat-italic {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: italic;
}

.hero-title-coloured-p {
  align-self: center;
  font-size: clamp(2rem, 5vw, 4rem);
  background: -webkit-linear-gradient(360deg, var(--primary), var(--secondary));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
  margin-block-start: 0px;
}

a {
  text-decoration: none;
}

.badge {
  width: 250px;
  height: 100px;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.event-face-ticket_no {
  color: var(--white);
  align-self: center;
  padding: 3px 6px 3px 6px;
  border-radius: 9px;
  background: var(--primary);
  font-weight: bold;
}

.ticket-no {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.event-face-ticket_no-secondary {
  color: var(--white);
  align-self: center;
  padding: 3px 6px 3px 6px;
  border-radius: 9px;
  background: var(--secondary);
  font-weight: bold;
}

.event-hero {
  min-height: -moz-fit-content;
  min-height: fit-content;
  background: linear-gradient(0deg, var(--secondary), var(--primary));
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  text-align: center;
  flex-grow: 1;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  padding-bottom: 8px;
  text-align: center;
  color: #fff;
  -webkit-box-flex: 1;
}



.event-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: none;
  color: var(--writing);
  font-size: 1rem;
  font-weight: bold;
}

.sellerCheck-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  color: var(--light-gray);
}

.event {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 40px;
  max-width: 850px;
  width: 100%;
  min-height: 250px;
  height: 100%;
}

.eventer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  width: clamp(250px, 100%, 800px);
  min-height: 250px;
  height: 100%;
}

.event-title {
  color: var(--white);
  font-size: clamp(1.7rem, 7vw, 3rem);
  margin: 0;
}
.event-date {
  color: var(--primary);
  font-size: 1.4rem;
  font-weight: 600;
  text-shadow: black 0px 0px 50px;
  color: var(--white);
  margin-right: 30px;
}

.event-location {
  font-size: 1.4rem;
  font-weight: 600;
  text-shadow: black 0px 0px 50px;
  color: var(--white);
}

@media screen and (max-width: 960px){
  .event-title {
    font-size: 1.6rem;
  }

  .event-date {
    font-size: 0.8rem;
  }

  .event-location {
    font-size: 0.8rem;
  }
}


@media screen and (max-width: 1440px) {
  .event-date {
    font-size: 1.1rem;
  }

  .event-location {
    font-size: 1.1rem;
  }
}


.event-image {
  border-radius: 9px;
  margin-top: 20px;
  object-fit: cover;
}

.listing {
  color: var(--writing);
  font-weight: bold;
  cursor: pointer;
  -webkit-transition: transform 0.3s !important;
  transition: transform 0.3s !important;
}

.listing:hover {
  -webkit-transform: scale(1.05) !important;
  transform: scale(1.05) !important;
}

.listing-type {
  align-self: center;
  margin-left: 5vw;
  margin-right: 5vw;
}


.event-back {
  width: clamp(250px, 90%, 600px);
}

.show-reviews {
  color: var(--light-gray);
  font-weight: 400;
}

.show-reviews:hover {
  color: var(--primary);
  cursor: pointer;
}

.rating {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-top: 0.5rem;
}


.no-listings {
  font-size: 1.2rem;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 450px;
  min-width: 300px;
  height: 100%;
  margin-top: 5vh;
  text-align: center;
}

.login-description {
  color: var(--light-gray);
  font-size: 1rem;
  margin: 0.5rem 0 0.4rem 0;
}

/* Create event page css */

.sell-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
  width: 100%;
  height: 100%;
}

.sell-label {
  color: var(--light-gray);
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.sell-input {
  height: 100%;
  font-family: inherit;
  min-width: 350px;
  max-width: 410px;
  min-height: 20px;
  padding: 5px;
  border-radius: 9px;
  border: 1px solid var(--light-gray);
  background: var(--surface);
  color: var(--writing);
  font-size: 1rem;
  margin-bottom: 2vh;
}

.sell-input:focus {
  outline: none;
  border: 1px solid var(--primary);
}

#low-margin {
  margin-bottom: 1rem;
}

.price-description {
  color: var(--writing);
  font-size: 1rem;
}

input[type="number"] {
  -webkit-appearance: textfield;
     -moz-appearance: textfield;
          appearance: textfield;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
}


/* Profile page css */

.profile-page {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: inherit;
  height: inherit;
  padding: 2rem;
}


.info-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 65%;
  background: var(--surface);
  border-radius: 9px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  min-width: 200px;
}

.info-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  min-width: 200px;
  border-top: var(--gray-active) 1px solid;
}

.info-item-text {
  color: var(--light-gray);
  font-size: 1rem;
  margin-left: 1rem;
}

#delete {
  background: transparent !important;
  box-shadow: none !important;
}

.review-box {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  width: 100%;
  min-width: 200px;
  background: var(--background);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  margin-bottom: 1rem;
}

.review-box-header {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 97%;
  min-width: 200px;
  border-bottom: var(--gray-active) 1px solid;
  margin: 0.5rem;
  padding-bottom: 0.5rem;
}

.review-box-header-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--gray-active);
  margin-right: 10px;
}

.review-box-header-rating {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.review-box-header-name {
  color: var(--writing);
  font-size: 1.2rem;
  margin: 0.25rem;
  margin-left: 0;
}

.review-box-body {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  width: 100%;
  min-width: 200px;
  margin-left: 1rem;
  width: 98%;
}

.review-box-body-text {
  color: var(--light-gray);
  font-size: 1rem;
  margin: 0.5rem;
}

.flex-centre {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.caret-up {
  display: none;
}

.caret-down {
  display: block;
}

/* Profile Payouts */

.bank-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 97%;
  height: 100%;
  background: var(--on-surface);
  border-radius: 9px;
  padding: 0.5em;
  margin-bottom: 0.75rem;
}

.bank-details-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-left: 1em;
  color: var(--writing);
  font-size: 1rem;
  font-weight: 500;
}

.bank-details-item p {
  margin-right: 0.25rem;
}

/* Checkout Page */
 .checkout-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
 }

 .StripeElement {
  width: 95%;
 }

 /* error message */
 .error-message {
  background: var(--error);
  color: var(--white);
  font-size: 1rem;
  margin: 0;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  padding: 0.5rem;
  border-radius: 9px;
  width: 90%;
  text-align: center;
 }

/* Toast */

.toast-success {
  display: flex;
  background: var(--surface);
  border: var(--success) 2px solid;
  color: var(--white);
  font-size: 1rem;
  position: fixed;
  z-index: 1;
  padding: 10px;
  left: 40%;
  top: 5%;
  width: 300px;
  min-height: 50px;
  overflow: auto;
  border-radius: 9px;
  transition: 2s;
}

#centre {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

#terms-padding {
  width: 95%;
}

.back-button {
  background: var(--surface);
  color: var(--writing);
  border: none;
  border-radius: 9px;
  padding: 0.5em 1em 0.5em 1em;
  align-self: flex-start;
  font-size: 0.9em;
}
.back-button:hover {
  box-shadow: var(--surface) 0px 0px 0px 2px;
}

.back-button-p:active {
  box-shadow: var(--surface) 0px 0px 0px 2px inset;
}


.profile-row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
  flex-wrap: wrap;
}

.main-div {
  width: clamp(200px, 75%, 1400px);
  height: 100%;
}

.center-page {
  display: flex;
  justify-content: center;
}

.ais-SearchBox-input {
  box-shadow: none;
  height: 40px;
}
.ais-Hits-item {
  box-shadow: none;
}

.ais-Hits {
  min-height: 50vh;
}

.ais-Hits-item {
  padding: 0.25em;
}

.ais-SearchBox-input:focus {
  border-color: var(--primary);
}

.ais-SearchBox-input:hover {
  border-color: rgba(0, 0, 0, 0.87);
}

.ais-SearchBox-input:focus:hover {
  border-color: var(--primary);
}

.hero-p {
  color: #4A4A4A;
  font-size: clamp(0.6rem, 5vw, 1.2rem);
}

.login-email-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 1rem;
}

.or-wrap {
  text-align: left;
  box-sizing: border-box;
  width: 100%;
  margin-left: 14px;
  margin-top: -7px;
  margin-bottom: 7px;
}

.or-line {
  text-align: left;
  box-sizing: border-box;
  vertical-align: middle;
  display: inline-block;
  width: calc(45%);
  height: 1px;
  background-color: #121212;

}

.or-text {
  box-sizing: border-box;
  vertical-align: middle;
  display: inline-block;
  width: 10%;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
}